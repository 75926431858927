import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  Timestamp,
  doc,
  onSnapshot,
  query,
  updateDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import {
  isUserAdmin,
} from "../util";
import dayjs from "dayjs";

export default function AdminPage() {
    const [currentUser, setCurrentUser] = useState<any | null>(null);
    const [open, setOpen] = useState(false);
    const [time, setTime] = useState("");
    const [error, setError] = useState("");
    const [sessionType, setSessionType] = useState("Private Session");
    const [isActive, setIsActive] = useState(false);
    const [customerDialogOpen, setCustomerDialogOpen] = useState(false);
    const [customers, setCustomers] = useState<any[]>([]);
    const [selectedCustomer, setSelectedCustomer] = useState<any>(null);

    useEffect(() => {
        const db = getFirestore();
        const customersCollectionRef = collection(db, "customers");
        const q = query(customersCollectionRef);

        const unsubscribe = onSnapshot(q, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                if (change.type === "added") {
                    setCustomers((customers) => [
                        ...customers,
                        { ...change.doc.data(), id: change.doc.id },
                    ]);
                }
                if (change.type === "modified") {
                    setCustomers((customers) =>
                        customers.map((customer) =>
                            customer.id === change.doc.id
                                ? { ...change.doc.data(), id: change.doc.id }
                                : customer
                        )
                    );
                }
                if (change.type === "removed") {
                    setCustomers((customers) =>
                        customers.filter((customer) => customer.id !== change.doc.id)
                    );
                }
            });
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        getAuth().onAuthStateChanged((user) => {
            setCurrentUser(user ?? null);
            if (!user || !isUserAdmin(user)) window.location.href = "/";
        });
    });

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleCreate = async () => {
        console.log(time, sessionType, isActive);
        const date = new Date(`${time}:00-04:00`);
        date.setSeconds(0);
        date.setMilliseconds(0);
        const db = getFirestore();
        const slotsCollectionRef = collection(db, "slots");

        const slots = await getDocs(slotsCollectionRef);
        const slotsAtTime = slots.docs.filter((doc) => {
            const slotDate = dayjs((doc.data().startTime as Timestamp).toMillis());
            return (
                slotDate.isSame(dayjs(date), "minute") &&
                doc.data().service === sessionType
            );
        });

        if (slotsAtTime.length > 0) {
            setError("There is already a slot at this time.");
            return;
        }

        const newSlot = {
            startTime: date,
            maxBookings: sessionType === "Private Session" ? 1 : 2,
            bookingIds: [],
            service: sessionType,
            active: isActive,
        };

        await addDoc(slotsCollectionRef, newSlot);
        handleClose();
    };

    const runScript = async () => {
        const db = getFirestore();
        const slotsCollectionRef = collection(db, "slots");

        const newSlots = generateNewSlots();

        console.log(newSlots);
        await Promise.all(
            newSlots.map(async (slot) => {
                await addDoc(slotsCollectionRef, slot);
            })
        );
    };

    const generateNewSlots = () => {
        const newSlots: {
            startTime: Date;
            maxBookings: number;
            bookingIds: never[];
            service: string;
            active: boolean;
        }[] = [];

        let classStartTime = new Date();
        classStartTime.setUTCFullYear(2025);
        classStartTime.setUTCMonth(0);
        classStartTime.setUTCDate(3);
        classStartTime.setUTCHours(16);
        classStartTime.setUTCMinutes(30);
        classStartTime.setUTCSeconds(0);

        do {
            newSlots.push({
                startTime: classStartTime,
                maxBookings: 10,
                bookingIds: [],
                service: "Drop-In Class",
                active: false,
            });
            classStartTime = new Date(classStartTime);
            classStartTime.setUTCDate(classStartTime.getUTCDate() + 7);
        } while (classStartTime.getTime() < new Date(2025, 3, 1).getTime());

        return newSlots;
    };

    const handleCustomerChange = (event: any, newValue: any) => {
        setSelectedCustomer({
            ...newValue,
            name: newValue?.name ?? "",
            email: newValue?.email ?? "",
            phone: newValue?.phone ?? "",
            privateCredits: newValue?.privateCredits ?? 0,
            duetCredits: newValue?.duetCredits ?? 0,
            classCredits: newValue?.classCredits ?? 0,
        });
    };

    const handleCustomerSave = async () => {
        const db = getFirestore();
        const customerRef = doc(db, "customers", selectedCustomer.id);
        await updateDoc(customerRef, {
            name: selectedCustomer.name,
            email: selectedCustomer.email,
        });
        setCustomerDialogOpen(false);
    };

    const handleCreditChange = async (type: string, operation: string) => {
        const db = getFirestore();
        const customerRef = doc(db, "customers", selectedCustomer.id);
        const creditField = `${type}Credits`;
        const currentCredits = selectedCustomer[creditField];

        if (operation === "add") {
            await updateDoc(customerRef, {
                [creditField]: currentCredits + 1,
            });
            setSelectedCustomer({
                ...selectedCustomer,
                [creditField]: currentCredits + 1,
            });
        } else if (operation === "remove" && currentCredits > 0) {
            await updateDoc(customerRef, {
                [creditField]: currentCredits - 1,
            });
            setSelectedCustomer({
                ...selectedCustomer,
                [creditField]: currentCredits - 1,
            });
        }
    };

    return (
        <>
            {!currentUser || !isUserAdmin(currentUser) ? (
                <></>
            ) : (
                <div style={{ display: "flex", justifyContent: "center", gap: "1rem" }}>
                    {currentUser.email === "josh.b.taekman@gmail.com" && (
                        <Button variant="contained" onClick={runScript}>
                            Run DB Script
                        </Button>
                    )}
                    <Button variant="contained" onClick={handleOpen}>
                        Add Time Slot
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setCustomerDialogOpen(true)}
                    >
                        Customer Lookup
                    </Button>
                </div>
            )}

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Time Slot</DialogTitle>
                <DialogContent
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingTop: "20px",
                    }}
                >
                    <TextField
                        label="Date and Time"
                        type="datetime-local"
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <FormControl variant="outlined" style={{ marginTop: 20 }}>
                        <Select
                            value={sessionType}
                            defaultValue="Private Session"
                            onChange={(e) => setSessionType(e.target.value)}
                        >
                            <MenuItem value="Private Session">Private Session</MenuItem>
                            <MenuItem value="Duet Session">Duet Session</MenuItem>
                            <MenuItem value="Drop-In Class">Drop-In Class</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isActive}
                                onChange={(e) => setIsActive(e.target.checked)}
                            />
                        }
                        label="Active"
                        style={{ marginTop: 20 }}
                    />
                    {error && <div style={{ color: "red", marginTop: 20 }}>{error}</div>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="info">
                        Cancel
                    </Button>
                    <Button onClick={handleCreate} color="info">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={customerDialogOpen}
                onClose={() => setCustomerDialogOpen(false)}
                PaperProps={{
                    style: {
                        minWidth: "500px",
                    },
                }}
            >
                <DialogContent>
                    <Autocomplete
                        options={customers}
                        getOptionLabel={(option) => `${option.name} (${option.email})`}
                        filterOptions={(options, params) => {
                            const filtered = options.filter(
                                (option) =>
                                    option?.name
                                        ?.toLowerCase()
                                        .includes(params?.inputValue?.toLowerCase()) ||
                                    option?.email
                                        ?.toLowerCase()
                                        .includes(params?.inputValue?.toLowerCase())
                            );
                            return filtered;
                        }}
                        onChange={handleCustomerChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Search customers"
                                variant="outlined"
                            />
                        )}
                    />
                    {selectedCustomer && (
                        <div>
                            <TextField
                                label="Name"
                                value={selectedCustomer?.name}
                                onChange={(e) =>
                                    setSelectedCustomer({
                                        ...selectedCustomer,
                                        name: e.target.value,
                                    })
                                }
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Email"
                                value={selectedCustomer?.email}
                                onChange={(e) =>
                                    setSelectedCustomer({
                                        ...selectedCustomer,
                                        email: e.target.value,
                                    })
                                }
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Phone"
                                value={selectedCustomer?.phone}
                                onChange={(e) =>
                                    setSelectedCustomer({
                                        ...selectedCustomer,
                                        phone: e.target.value,
                                    })
                                }
                                fullWidth
                                margin="normal"
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleCustomerSave}
                            >
                                Save
                            </Button>
                            <br />
                            <br />
                            <div
                                style={{ display: "flex", alignItems: "center", gap: "10px" }}
                            >
                                <p>
                                    Remaining Private Credits:{" "}
                                    {selectedCustomer?.sessionCredits ?? 0}
                                </p>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleCreditChange("session", "add")}
                                >
                                    Add
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => handleCreditChange("session", "remove")}
                                >
                                    Remove
                                </Button>
                            </div>
                            <div
                                style={{ display: "flex", alignItems: "center", gap: "10px" }}
                            >
                                <p>
                                    Remaining Duet Credits: {selectedCustomer?.duetCredits ?? 0}
                                </p>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleCreditChange("duet", "add")}
                                >
                                    Add
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => handleCreditChange("duet", "remove")}
                                >
                                    Remove
                                </Button>
                            </div>
                            <div
                                style={{ display: "flex", alignItems: "center", gap: "10px" }}
                            >
                                <p>
                                    Remaining Class Credits: {selectedCustomer?.classCredits ?? 0}
                                </p>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleCreditChange("class", "add")}
                                >
                                    Add
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => handleCreditChange("class", "remove")}
                                >
                                    Remove
                                </Button>
                            </div>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setCustomerDialogOpen(false);
                            setSelectedCustomer(null);
                        }}
                        color="primary"
                        variant="contained"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
